import React, { useState, useRef } from 'react'
import { Collapse } from 'antd'
const HiringWithMedRepsFaqComponent = () => {
  const [viewMore, setViewMore] = useState(false)
  const { Panel } = Collapse
  const myRef = useRef(null)
  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
  const executeScroll = () => scrollToRef(myRef)
  return (
    <>
      <div className="container mx-auto py-9 md:py-16">
        <div className="w-[85%] md:w-4/5 mx-auto">
          <h1 className="text-4xl text-center">Frequently Asked Questions</h1>
          <div className="bg-white shadow-profileCard rounded-lg mt-16">
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header=" What is MedReps ?" key="1">
                <div className="px-5 md:px-8">
                  <div className="mb-2">
                    <p className="font-sans text-sm leading-6">
                      MedReps is the go-to site for medical sales. MedReps
                      provides medical sales opportunities and targeted career
                      resources for medical sales professionals, connecting
                      medical sales employers with experienced medical sales
                      talent.
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div
            className="bg-white shadow-profileCard rounded-lg mt-8"
            ref={myRef}
          >
            <Collapse
              className="w-full compare-data-collapse faq-collapse"
              defaultActiveKey={['1']}
              expandIconPosition="right"
              expandIcon={({ isActive }) => {
                if (isActive) {
                  return <img src="/icons/icon-collapse-minus.svg" />
                } else {
                  return <img src="/icons/icon-collapse-plus.svg" />
                }
              }}
            >
              <Panel header="Why should I post jobs on MedReps?" key="1">
                <div className="px-5 md:px-8">
                  <div className="mb-2">
                    <p className="font-sans text-sm leading-6">
                      MedReps is a paid-for, members only platform, meaning our
                      candidate community is high-quality and actively looking
                      for their next role.
                    </p>
                  </div>
                </div>
              </Panel>
            </Collapse>
          </div>

          {viewMore && (
            <>
              <div className="bg-white shadow-profileCard rounded-lg mt-5 lg:mt-8">
                <Collapse
                  className="w-full compare-data-collapse faq-collapse"
                  defaultActiveKey={['1']}
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => {
                    if (isActive) {
                      return <img src="/icons/icon-collapse-minus.svg" />
                    } else {
                      return <img src="/icons/icon-collapse-plus.svg" />
                    }
                  }}
                >
                  <Panel header="Educated" key="1">
                    <div className="px-8">
                      <div className="mb-0">
                        <ul className="ml-0 font-sans text-sm faq-icon-list">
                          <li className="flex mb-6">
                            <div className="mr-4">
                              <img src="/icons/icon-check-filled.svg" />
                            </div>
                            73% hold a Bachelor’s degree
                          </li>
                          <li className="flex mb-6">
                            <div className="mr-4">
                              <img src="/icons/icon-check-filled.svg" />
                            </div>
                            20% hold a Master’s/MBA
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </>
          )}
        </div>
        <div className="text-center">
          <button
            className="text-white py-4 px-10 bg-button cursor-pointer font-sans font-bold rounded text-lg mt-12"
            onClick={() => {
              setViewMore(!viewMore)
              executeScroll()
            }}
          >
            {viewMore ? 'View Less' : 'View More'}
          </button>
        </div>
      </div>
    </>
  )
}
export default HiringWithMedRepsFaqComponent
