import React from 'react'

const HiringWithMedRepsReviews = () => {
  return (
    <div className="container mx-auto pb-20 mt-32">
      <div className="w-full lg:w-4/5 mx-auto">
        {' '}
        <div className="flex flex-col md:flex-row items-center">
          <div className="mx-auto w-[85%] sm:w-1/2 relative">
            <img src="/images/Steve-Dodge.png" alt="Steve-Dodge" />
          </div>
          <div className="w-full sm:w-1/2 pl-12 mt-10 sm:mt-0">
            <div className="qoute-div mr-0 md:mr-[6rem]">
              <p className="font-sans text-2xl md:text-lg font-normal leading-[32px] pt-2 relative">
                <img
                  src="/images/qoute.png"
                  className="absolute left-0 -top-10"
                />
                It saves time, is geared to our industry, and makes us as
                recruiters more efficient
                <img
                  className="rotate-180 absolute right-0 -bottom-8"
                  src="/images/qoute.png"
                />
              </p>

              <h1 className="font-sans text-xl md:text-lg font-bold pt-4 m-0 pb-1">
                Steven Dodge
              </h1>
              <p className="font-sans text-xl md:text-base">
                Sr Sales and Marketing Recruiter, Teleflex
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HiringWithMedRepsReviews
