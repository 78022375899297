import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import { PrevArrow, NextArrow } from './carousel-arrows'

const EmployerSlider = ({ headerText }) => {
  const featuredEmployersData = useStaticQuery(graphql`
    query AllFeaturedEmployers {
      allStrapiFeaturedEmployer {
        nodes {
          id
          logo {
            url
          }
          strapiId
        }
      }
    }
  `)
  let featuredEmployers =
    featuredEmployersData.allStrapiFeaturedEmployer?.nodes || []
  featuredEmployers = featuredEmployers.sort(() => Math.random() - 0.5)

  if (!headerText) {
    headerText = 'Hiring now on MedReps'
  }

  const settings = {
    className: 'slick-slider',
    dots: false,
    infinite: true,
    slidesToShow: featuredEmployers.length > 6 ? 6 : featuredEmployers.length,
    slidesToScroll: 6,
    swipe: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          infinite: featuredEmployers.length < 5 ? false : true,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: featuredEmployers.length < 3 ? false : true,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: false,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <>
      {featuredEmployers?.length ? (
        <div className="container mx-auto pt-16">
          <div className="w-4/5 mx-auto">
            <h1 className="text-headingFour font-normal text-center mb-0 capitalize">
              {headerText}
            </h1>
            <div className="py-8">
              <Slider {...settings}>
                {featuredEmployers.map(item => (
                  <Link
                    key={item.id}
                    className="flex justify-center items-center w-auto mx-5 cursor-pointer"
                    to={`/featured-employers/featured-employer-${item.strapiId}`}
                  >
                    <img
                      className="h-20 border-none outline-none"
                      src={item.logo?.url}
                    />
                  </Link>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
export default EmployerSlider
