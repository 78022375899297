import React from 'react'
import cookie from 'react-cookies'
import { Link } from 'gatsby'
const HiringWithMedRepsSignup = () => {
  let user = cookie.load('user')
  return (
    <div className="bg-themePink py-32 mt-12 px-0 md:px-5">
      <div className="container mx-auto">
        <div className="w-full lg:w-4/5 mx-auto">
          {' '}
          <div className="flex flex-col md:flex-row items-center">
            <div className="mx-auto w-[85%] sm:w-[42%]">
              <div className="w-full h-full">
                <img src="/images/Signup-Image.png" alt="Signup-Image" />
              </div>
            </div>
            <div className="w-full sm:w-[53%] pl-12 mt-10 sm:mt-0">
              <div className="qoute-div mr-0 md:mr-[6rem]">
                <h1 className="text-4xl leading-[3.5rem] m-0">
                  83% of our members are looking for a new job
                </h1>
                <p className="text-xl pt-2">
                  Sign up now to start posting jobs
                </p>
                {!user && (
                  <Link to="/employer/signup/">
                    <button className="text-white py-4 px-10 bg-button cursor-pointer font-sans font-bold rounded text-lg mt-8">
                      Sign Up
                    </button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HiringWithMedRepsSignup
