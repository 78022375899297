import React from 'react'
const HiringWithMedRepsCharts = () => {
  return (
    <div className="pt-24 container mx-auto w-full lg:w-[72%] px-8 md:px-0 relative">
      <h1 className="text-left md:text-center text-2xl">
        WHAT MAKES MEDREPS DIFFERENT?
      </h1>
      {/* Experienced Section */}
      <div className="flex flex-col md:flex-row justify-between items-center pt-12">
        <div className="w-full md:w-[40%]">
          <div className="w-full md:w-[80%]">
            <h1 className="text-3xl">Experienced.</h1>
            <p className="text-2xl pt-6 md:leading-[3rem]">
              87% of MedReps members have medical sales experience
            </p>
          </div>
        </div>
        <div className="w-full md:w-[50%] relative mt-12 lg:mt-0">
          <h1 className="text-xl m-0 text-center mb-12 lg:mb-6">
            96% Have General Sales Experience
          </h1>

          <div className="w-full h-full min-h-[252px] md:min-h-[456px]">
            <img
              className="rounded-md"
              src="/images/experienced.png"
              alt="experienced"
            />
          </div>
        </div>
      </div>
      {/* Educated Section */}
      <div className="flex flex-col md:flex-row-reverse justify-between items-center pt-12">
        <div className="w-full md:w-[40%]">
          <div className="w-full md:w-[70%]">
            <h1 className="text-3xl">Educated.</h1>
            <p className="text-2xl pt-6">73% hold a Bachelor’s degree</p>
            <p className="text-2xl">20% hold a Master’s/MBA</p>
          </div>
        </div>
        <div className="w-full md:w-[50%] ">
          <div className="w-full h-full min-h-[252px] md:min-h-[400px]">
            <img
              className="rounded-md"
              src="/images/educated.png"
              alt="educated"
            />
          </div>
        </div>
      </div>
      {/* Employable Section */}
      <div className="flex flex-col md:flex-row justify-between items-center pt-24">
        <div className="w-full md:w-[43%]">
          <div className="w-full">
            <h1 className="text-3xl">Employable.</h1>
            <p className="text-2xl pt-6">73% are employed full-time</p>
            <p className="text-2xl">
              71% are employed in medical / pharma sales
            </p>
          </div>
        </div>
        <div className="w-full md:w-[50%] ">
          <div className="w-full h-full min-h-[252px] md:min-h-[465px]">
            <img
              className="rounded-md"
              src="/images/employable.png"
              alt="employable"
            />
          </div>
        </div>
      </div>
      <img
        src="/images/home-hero-vector-blue.webp"
        className="rotate-180 absolute bottom-8 -left-12 "
      />
    </div>
  )
}
export default HiringWithMedRepsCharts
