import React from 'react'
import HubspotForm from 'react-hubspot-form'
import useGMT from '../../../hooks/useGTM'
import { HUBSPOT_INTEGRATION } from '../../../data'

const HiringWithMedRepsContactUs = () => {
  const { pushContactUsEvent } = useGMT()

  const onHubspotFormReady = () => {
    setTimeout(() => {
      const element = document
        .querySelector('iframe')
        .contentWindow.document.getElementsByClassName('hs-button')
      if (element[0]) {
        element[0].style.background = '#a51440'
        element[0].style.border = 'none'
      }
    }, 500)
  }

  const handleSubmit = () => {
    console.log('Submit!')
    pushContactUsEvent()
  }

  return (
    <div className="container mx-auto w-full md:w-4/5 pt-12 pb-24">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-full md:w-[32%] px-5 md:px-0">
          <h1 className="text-3xl md:text-[3rem] leading-[3.5rem]">
            Still have questions? Contact us today
          </h1>
        </div>

        <div className="hiring-with-medReps-form bg-[#F1F6F8] pt-14 pb-20 px-10 w-full md:w-[53%] relative">
          <img
            src="/images/home-hero-vector-blue.webp"
            className="rotate-180 absolute top-0 right-0 -mt-16 -mr-0 md:-mr-16 z-10"
          />
          <HubspotForm
            region="na1"
            portalId={HUBSPOT_INTEGRATION.PORTAL_ID}
            formId={HUBSPOT_INTEGRATION.HIRING_CONTACT_FORM_ID}
            onSubmit={handleSubmit}
            onReady={onHubspotFormReady}
            loading={<div>Loading...</div>}
          />
          <img
            src="/images/home-hero-vector-red.webp"
            className="rotate-[20deg] absolute z-10 -bottom-[4rem] home-hero-vector-red -ml-[14rem]"
          />
        </div>
      </div>
    </div>
  )
}
export default HiringWithMedRepsContactUs
