import React from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

export const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <div {...props}>
    <RiArrowRightSLine />
  </div>
);

export const PrevArrow = ({ currentSlide, slideCount, ...props }) => (
  <div {...props}>
    <RiArrowLeftSLine />
  </div>
);
