import React from 'react'
import { Link } from 'gatsby'
import cookies from 'react-cookies'

const HiringWithMedRepsPostNow = () => {
  let user = cookies.load('user')
  return (
    <>
      <div className="px-[30px] py-10 lg:py-32 bg-themePink w-full mt-12">
        <div className="container mx-auto flex flex-col justify-center items-center w-full md:w-[34%] mx-auto">
          <h1 className="text-[#A51440] text-2xl font-bold">$345</h1>
          <h1 className="text-[26px] lg:text-4xl font-normal text-center mb-2">
            30-day Job Posting
          </h1>
          <p className="font-normal font-sans text-base text-center pt-4">
            Only have one job to post? Get a 30-day job posting, with the option
            to upgrade to a Featured Job
          </p>
          <div className="w-6/12 flex items-center mx-auto justify-center pt-4">
            {!user && (
              <Link to="/employer/signup/">
                <button className="text-white py-4 px-8 bg-[#A51440] cursor-pointer font-sans font-bold rounded">
                  Post now
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export default HiringWithMedRepsPostNow
